@import url("https://fonts.googleapis.com/css?family=Roboto");
/* Global Styles */

@import url("https://fonts.googleapis.com/css2?family=Amiri&family=Open+Sans&family=Poiret+One&family=Reem+Kufi&family=Roboto&display=swap");
:root {
  --primary-color: #0085b8;
  --dark-color: #09496e;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --my-color :teal
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Amiri", serif;
  direction: rtl;
  font-size: 1rem;
  line-height: 1.6;
  background-color: rgb(255, 255, 255) !important;
  color: #333;
}


#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
/* Login Page */
#login ,#regester{
  background :#fff;
  max-height:100vh;
  width: 100vw;
}
#login .login-side ,#regester .login-side{ 
  height: 100vh;
  width: 100%;
  background: url(./img/2.jpg);
  background-size:cover;background-position:center 0;overflow:hidden;position:relative;
 min-height:100px;padding-bottom:10px;
}
#login .form{
  margin: 2rem auto ;
  text-align: center;
  width: 100%;

}
#regester .form {
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 4px;
}

#login .gutter-row ,#regester .gutter-row{
  max-height: 100vh;
  width: 100%;
  padding: 0px;
  overflow: scroll;
}

#login .side ,#regester .side {
  padding-left: 0 !important;
 }

.demo-infinite-container {
  height: 300px;
  padding: 8px 24px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
 
.mt-1{
  margin-top: 0.2rem;
}
@media (max-width: 700px) {
#login .side ,#regester .side {
 display: none;
}
.showcase {
  height: auto;
}
#login .gutter-row{
  padding: 30px !important;
}
}

.showcase {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  color: #fff;
}


.video-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--primary-color) url("/img/1.jpg") no-repeat center center/cover;
}

.content {
  z-index: 10;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(161, 125, 140, 0.8);
}

.center-align {
  text-align: center;
}



.grid-Style {
  direction: rtl;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  font-size: "1.2rem";
}

.grid-2 {
  padding: 1rem 0.5rem;
  direction: rtl;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: "1.2rem";
}

.grid-3 {
  padding: 1rem 0.5rem;
  direction: rtl;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  font-size: "1.2rem";
}
 
#ll img{
  max-width: 200px;
}

#level {
  background: url(/img/levelbg.gif);
  height: 60vh;
}
.mt-5 {
  margin-top: 4rem;
}

.mb-5 {
  margin-bottom: 4rem;
}

.my-3 {
  margin: 3rem 0;
}

.my-4 {
  margin: 4rem 0;
}
.teal-text {
  color: teal;
}
/* #course {
  height: 30vh;
} */


.btn-my, .bg-my, .badge-my, .alert-my {
  background: var(--my-color);
  color: #fff;
}


.text-justify {
  text-Align: justify;
  text-Justify: inter-word;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  width: max-content;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease-in;
  text-align: center;
  outline: none;
}
/* News && Advs */
.ant-tabs-tab-btn{
  font-size: 1.1rem;
}
.ant-menu-item{
  text-align: right;
  font-size: 1rem;
}

.ant-menu-item span{
  margin: auto 0.4rem ;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  color: #666;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* Utilities */

.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

.container-fluid {
  margin: auto;
  overflow: scroll;
  padding: 1rem 1.2rem;
}

.card-content {
  line-height: 3rem;
}

.collection-item {
  font-size: 1.1rem;
  line-height: 3rem;
}

.collection-item a {
  font-size: 1.7rem;
}

/* Text Styles*/

.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-success {
  color: var(--success-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left;
}

.mt-5{
  margin-top: 5rem !important;
}

.mt-2{
  margin-top: 2rem !important;
}
.text-justify {
  text-align: justify;
  text-justify: inter-word;
  font-size: 1.3rem;
}

/* Posts Page */

.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr !important;
  grid-gap: 2rem;
  align-items: center;
}

.post>div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.display {
  display: block;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
}

.hide {
  display: none
}

.post-image {
  cursor: pointer;
}

.display .content-image {
  position: absolute;
  top: 4%;
  left: 5%;
  width: 90%;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-right: 10%;
}

.display img {
  width: 85%;
  box-shadow: #fff 2px 2px 4px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Center All */

.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Cards */

.form-card {
  padding: 1rem;
  border: #ccc 1px dotted;
  margin: 0.7rem 0;
  max-width: 60%;
}

/* Cards */

.card {
  padding: 0.8rem;
  line-height: 2rem;
  margin: 0.7rem 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  margin: 0.5rem 0 1rem;
  border-radius: 8px;
  background-color: #fff;
}
.card-link {
  width: 100%;
  padding: 0.8rem 4rem;
  line-height: 2rem;
  margin: 0.7rem 0.5rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.25s;
  margin: 0.5rem 0 1rem;
  border-radius: 8px;
  background-color: #fff;
}
@media (max-width: 576px) {
  .card-link {
     padding: 0.2rem 1.2rem;
     margin: 0.5rem 0;

  }

}

/* List */

.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}

/* Padding */

.p {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.py {
  padding: 0.5rem 0;
}

.py-1 {
  padding: 1rem 0;
}

.py-2 {
  padding: 2rem 0;
}

.py-3 {
  padding: 3rem 0;
}

/* Margin */

.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}

.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mx {
  margin: 0 0.7rem;
}

.mx-1 {
  margin: 0 1rem;
}

.mx-2 {
  margin: 0 2rem;
}

.mx-3 {
  margin: 0 3rem;
}

/* Grid */

.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  width: max-content;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.max-content {
  width: max-content !important;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.left-align {
  text-align: left;
}

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary, .bg-primary, .badge-primary, .alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light, .bg-light, .badge-light, .alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark, .bg-dark, .badge-dark, .alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger, .bg-danger, .badge-danger, .alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success, .bg-success, .badge-success, .alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white, .bg-white, .badge-white, .alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light, .badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */

input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type="text"], input[type="email"], input[type="password"], input[type="date"], select, textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

input[type="submit"], button {
  font: inherit;
}

table th, table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 2px solid;
}

/* table th {
  background: var(--light-color);
} */

/* Navbar */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--light-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

.footer {
  font-size: 1.3rem;
  padding: 0.8rem;
  margin-top: 0.4rem;
}

.brand-logo {
  font-size: 1.4rem;
}

.card-content h1 {
  font-size: 2rem;
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

@media (max-width: 576px) {
  .grid-2 h2 {
    font-size: 1.2em;
  }
  .card-stacked h1 {
    font-size: 1.2em;
  }
  .card img {
    height: 250px;
  }
}

/* Mobile Styles */

@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }
  .grid-2, .grid-3, .grid-4 {
    grid-template-columns: 1fr;
  }
  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }
  .large {
    font-size: 2rem;
  }
  .lead {
    font-size: 1rem;
  }
  /* Navbar */
  .navbar {
    display: block;
    text-align: center;
  }
  .navbar ul {
    text-align: center;
    justify-content: center;
  }
  .post {
    grid-template-columns: 1fr;
  }
  .post a, .post button {
    padding: 0.3rem 0.4rem;
  }
}

.ant-timeline-item-content{
  padding: 0 3rem;
  font-size: 1.1rem;
}

.center {
  margin: 20px auto;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}



.bg-page .ant-list-item{
  cursor: pointer;
}


